import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass'
import moment from 'moment'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Content from '../components/content'
import CourseInfo from '../components/courseinfo'
import Pagetitle from '../components/pagetitle'
import Lesson from '../components/lesson'
import CourseSubscription from '../components/coursesubscription'

const Course = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { title, modules, next_course, mailchimp_group } = frontmatter

  const [isActiveCourse, setActiveCourse] = useState(false)
  useEffect(() => {
    if (!next_course) {
      return
    }

    const today = moment()
    const courseEndDate = moment(next_course.end_date, 'DD-MM-YYYY')

    setActiveCourse(today.isBefore(courseEndDate))
  }, [next_course])

  const renderProgram = () => {
    if (!modules) {
      return
    }

    if (modules.length === 1) {
      if (
        modules[0].program.length >= 1 &&
        modules[0].program[0].lesson_details
      ) {
        return (
          <Content>
            <Heading>Programma del corso</Heading>
            {modules[0].program.map((lesson, i) => (
              <Lesson
                lesson={lesson}
                number={i + 1}
                renderDates={isActiveCourse}
                key={i}
              />
            ))}
          </Content>
        )
      } else {
        // Solo una descrizione sommaria del programma
        return (
          <Content>
            <Heading>Programma del corso</Heading>
            <Text>{modules[0].program[0].lesson_description}</Text>
          </Content>
        )
      }
    } else {
      return (
        <Content>
          <Heading>Programma del corso</Heading>
          {modules.map((module, i) => {
            const Lessons = () =>
              module.program.map((lesson, j) => (
                <Lesson
                  lesson={lesson}
                  number={j + 1}
                  key={`${i}-${j}`}
                  renderDates={isActiveCourse}
                />
              ))
            return (
              <div key={i}>
                <Text
                  fontSize={2}
                  fontWeight="400"
                  mt={4}
                  mb={2}
                  fontFamily="Work Sans, sans-serif"
                  key={i}
                >
                  {module.module_name}
                </Text>
                <Lessons />
              </div>
            )
          })}
        </Content>
      )
    }
  }

  return (
    <Layout>
      <SEO title={title} />
      <Pagetitle>{title}</Pagetitle>
      <CourseInfo
        active={isActiveCourse}
        duration={frontmatter.duration}
        cost={frontmatter.cost}
        certificate={frontmatter.certificate}
      />
      <Container>
        <Box pt={3}>
          <Content dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
        <CourseSubscription
          active={isActiveCourse}
          course={{ ...next_course }}
          group={mailchimp_group}
        />
        <Box pb={4}>{renderProgram()}</Box>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CourseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        cost
        duration
        certificate
        mailchimp_group
        next_course {
          start_date
          end_date
          signup_pdf
        }
        modules {
          module_name
          program {
            lesson_description
            lesson_date
            lesson_details {
              lesson_argument
            }
          }
        }
      }
    }
  }
`

export default Course
