import React, { useState } from 'react'
import styled from 'styled-components'
import { Card, Text } from 'rebass'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const LessonDetail = styled.ul`
  margin: 0;
  padding: 0;

  li {
    list-style: square;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

const LessonSmall = styled(Text)`
  span {
    font-weight: 400;
    color: #555;
    margin-left: 8px;
  }
`

const Lesson = ({ lesson, number, renderDates }) => {
  const [isOpen, setOpen] = useState(false)

  const renderIcon = () => {
    if (!lesson.lesson_details || lesson.lesson_details.length === 0) {
      return
    }

    return isOpen && lesson.lesson_details ? (
      <IconContainer>
        <FiChevronUp />
      </IconContainer>
    ) : (
      <IconContainer>
        <FiChevronDown />
      </IconContainer>
    )
  }

  return (
    <Card
      borderRadius={4}
      boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
      p={3}
      mb={3}
      css={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => setOpen(!isOpen)}
    >
      {renderIcon()}
      <LessonSmall color="#f06449" fontWeight="600" fontSize={2} mb={2}>
        Lezione {number} <span>{renderDates && lesson.lesson_date}</span>
      </LessonSmall>
      <Text>{lesson.lesson_description}</Text>
      {isOpen && lesson.lesson_details ? (
        <LessonDetail>
          {lesson.lesson_details.map((detail, i) => (
            <li key={i}>
              <Text fontSize={2}>{detail.lesson_argument}</Text>
            </li>
          ))}
        </LessonDetail>
      ) : (
        ''
      )}
    </Card>
  )
}

export default Lesson
