import React from 'react'
import styled from 'styled-components'

const Container = styled.ul`
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #f3f3f3;
  background: #fff;

  position: sticky;
  top: 0;

  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 99;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    display: inline-block;
    margin: 0 16px;

    b {
      font-weight: 500;
    }
  }
`

const CourseInfo = ({ active, duration, cost, certificate }) => {
  return (
    <Container>
      {active && (
        <li>
          <b>Iscrizioni aperte</b>
        </li>
      )}
      <li>
        <b>Durata:</b> {duration}
      </li>
      <li>
        <b>Costo:</b> {cost}
      </li>
      <li>
        <b>{certificate}</b>
      </li>
    </Container>
  )
}

export default CourseInfo
