import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Card, Text, Flex } from 'rebass'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Content from './content'

const DownloadModule = styled.a`
  border-radius: 4px;
  background: #f06449;
  font-family: Work Sans, sans-serif;
  padding: 8px 16px;
  color: #fff !important;
  display: block;
  text-align: center;
  margin: 24px auto;
  max-width: 300px;
  cursor: pointer;
  outline: none;
  border: none;
`

const SubscribeButton = styled.button`
  border-radius: 4px;
  background: #f06449;
  font-family: Work Sans, sans-serif;
  padding: 8px 16px;
  color: #fff !important;
  display: block;
  text-align: center;
  margin: 24px auto;
  max-width: 300px;
  cursor: pointer;
  outline: none;
  border: none;
`

const FormInput = styled.input`
  padding: 16px;
  width: 100%;
  font-family: Work Sans, sans-serif;
  border-radius: 4px;
  border: 1px solid #f3f3f3;
`

const CourseSubscription = ({ active, course, group }) => {
  const [email, setEmail] = useState('')
  const [newsletterFields, setNewsletterFields] = useState({})
  const [results, setResults] = useState({})

  const handleSubscribeForm = async e => {
    e.preventDefault()
    const otherFields = {
      'gdpr[29049]': 'Y',
      'gdpr[29057]': 'Y',
      'gdpr[29053]': 'Y',
      PATHNAME: window.location.pathname,
    }

    const allFields = !!group
      ? {
          ...newsletterFields,
          'group[3261]': `${group}`,
          ...otherFields,
        }
      : { ...newsletterFields, ...otherFields }

    const result = await addToMailchimp(email, allFields)
    setResults(result)
  }

  const handleSubscribeChange = e => {
    setNewsletterFields({
      ...newsletterFields,
      [e.target.name]: e.target.value,
    })
  }

  if (active && course.signup_pdf) {
    return (
      <Box my={5}>
        <Content>
          <Card
            borderRadius={4}
            boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
            py={3}
            px={[3, 3, 5]}
          >
            <Text
              color="#0699A8"
              fontWeight="600"
              mb={3}
              fontFamily="Work Sans, sans-serif"
              textAlign="center"
            >
              Iscrizioni aperte!
            </Text>
            <Text fontFamily="Work Sans, sans-serif" textAlign="center">
              {course.start_date !== course.end_date
                ? `Il corso inizierà il ${course.start_date} e finirà il ${course.end_date}.`
                : `Il corso si terrà il ${course.start_date}.`}
            </Text>
            <DownloadModule href={course.signup_pdf} download>
              Scarica il modulo di iscrizione
            </DownloadModule>
          </Card>
        </Content>
      </Box>
    )
  } else if (!active) {
    return (
      <Box my={5}>
        <Content>
          <Card
            borderRadius={4}
            boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
            py={3}
            px={[3, 3, 5]}
          >
            <Text
              color="#0699A8"
              fontWeight="600"
              mb={3}
              fontFamily="Work Sans, sans-serif"
              textAlign="center"
            >
              Non perderti il prossimo corso
            </Text>
            {!results.result && (
              <form onSubmit={e => handleSubscribeForm(e)}>
                <Flex flexWrap="wrap">
                  <Box width={[1, 1, 1 / 2]} p={2}>
                    <FormInput
                      type="text"
                      name="FNAME"
                      placeholder="Nome"
                      required
                      onChange={e => handleSubscribeChange(e)}
                    />
                  </Box>
                  <Box width={[1, 1, 1 / 2]} p={2}>
                    <FormInput
                      type="text"
                      name="LNAME"
                      placeholder="Cognome"
                      required
                      onChange={e => handleSubscribeChange(e)}
                    />
                  </Box>
                  <Box width={1} p={2}>
                    <FormInput
                      type="email"
                      name="EMAIL"
                      placeholder="Email"
                      required
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Box>
                </Flex>
                <Box width={1} p={2}>
                  <Text
                    fontSize={2}
                    fontWeight="400"
                    my={2}
                    fontFamily="Work Sans, sans-serif"
                    textAlign="left"
                  >
                    Iscrivendoti dai il tuo consenso a ricevere occasionalmente
                    informazioni ed eventuali promozioni riguardanti i corsi in
                    programmazione per cui hai espresso interesse. Niente spam,
                    promesso.
                  </Text>
                </Box>
                <SubscribeButton type="submit">
                  Tienimi aggiornato
                </SubscribeButton>
              </form>
            )}
            {results.result && (
              <Text
                textAlign="center"
                dangerouslySetInnerHTML={{ __html: results.msg }}
              />
            )}
          </Card>
        </Content>
      </Box>
    )
  }

  console.error('The course is active but the signup pdf is missing.')
  return ''
}

export default CourseSubscription
